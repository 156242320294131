module.exports = [{
      plugin: require('/codebuild/output/src867922733/src/svatva-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SVATVA","short_name":"svatva-home","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/logo.svg"},
    },{
      plugin: require('/codebuild/output/src867922733/src/svatva-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src867922733/src/svatva-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
