// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src867922733/src/svatva-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookie-policy-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/CookiePolicy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-courses-aws-dev-associate-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/AwsDevAssociateTrng.js" /* webpackChunkName: "component---src-pages-courses-aws-dev-associate-trng-js" */),
  "component---src-pages-courses-aws-sol-arch-associate-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/AwsSolArchAssociateTrng.js" /* webpackChunkName: "component---src-pages-courses-aws-sol-arch-associate-trng-js" */),
  "component---src-pages-courses-back-end-developer-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/BackEndDeveloperTrng.js" /* webpackChunkName: "component---src-pages-courses-back-end-developer-trng-js" */),
  "component---src-pages-courses-business-analyst-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/BusinessAnalystTrng.js" /* webpackChunkName: "component---src-pages-courses-business-analyst-trng-js" */),
  "component---src-pages-courses-dev-ops-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/DevOpsTrng.js" /* webpackChunkName: "component---src-pages-courses-dev-ops-trng-js" */),
  "component---src-pages-courses-front-end-dev-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/FrontEndDevTrng.js" /* webpackChunkName: "component---src-pages-courses-front-end-dev-trng-js" */),
  "component---src-pages-courses-full-stack-dev-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/FullStackDevTrng.js" /* webpackChunkName: "component---src-pages-courses-full-stack-dev-trng-js" */),
  "component---src-pages-courses-mobile-app-dev-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/MobileAppDevTrng.js" /* webpackChunkName: "component---src-pages-courses-mobile-app-dev-trng-js" */),
  "component---src-pages-courses-software-testing-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/courses/SoftwareTestingTrng.js" /* webpackChunkName: "component---src-pages-courses-software-testing-trng-js" */),
  "component---src-pages-elements-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-terms-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/PrivacyTerms.js" /* webpackChunkName: "component---src-pages-privacy-terms-js" */),
  "component---src-pages-services-cloud-services-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/CloudServices.js" /* webpackChunkName: "component---src-pages-services-cloud-services-js" */),
  "component---src-pages-services-it-consulting-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/ITConsulting.js" /* webpackChunkName: "component---src-pages-services-it-consulting-js" */),
  "component---src-pages-services-it-support-services-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/ITSupportServices.js" /* webpackChunkName: "component---src-pages-services-it-support-services-js" */),
  "component---src-pages-services-seo-optimization-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/SEOOptimization.js" /* webpackChunkName: "component---src-pages-services-seo-optimization-js" */),
  "component---src-pages-services-software-trng-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/SoftwareTrng.js" /* webpackChunkName: "component---src-pages-services-software-trng-js" */),
  "component---src-pages-services-web-design-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/services/WebDesign.js" /* webpackChunkName: "component---src-pages-services-web-design-js" */),
  "component---src-pages-svatva-team-js": () => import("/codebuild/output/src867922733/src/svatva-website/src/pages/SvatvaTeam.js" /* webpackChunkName: "component---src-pages-svatva-team-js" */)
}

